.dr__contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 15rem;
  color: #fff;
  margin-top: 5rem;
}

.dr__contact-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dr__contact-title h1 {
  font-size: 50px;
  margin-bottom: 4rem;
}

.dr__contact p {
  font-size: 24px;
  padding-left: 25px;
}

.dr__contact-email {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.dr__contact-twitter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.dr__contact-discord {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.dr__contact img {
  height: 50px;
  width: 50px;
}

.dr__contact img:hover {
  transform: scale(1.1);
}

.dr__contact-footer p {
  font-size: 14px;
  margin-top: 5rem;
}

@media screen and (max-width: 1050px) {
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 550px) {
  .dr__contact-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 1000px;
  }

  .dr__contact h1 {
    font-size: 15px;
    text-align: center;
  }

  .dr__contact p {
    font-size: 14px;
    padding-left: 25px;
  }
}
