.dr__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
}

.dr__navbar-title {
  color: var(--color-text);
  font-size: 32px;
  letter-spacing: 10px;
}

.dr__navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.dr__navbar-links p {
  color: var(--color-text);
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.dr__navbar-links p:hover {
  color: var(--color-onhover);
  transform: scale(1.1);
}

.dr__navbar-icons img:hover {
  transform: scale(1.2);
}

.dr__navbar-links p:first-child,
p:nth-child(2) {
  padding-right: 4rem;
}

.dr__navbar-icons img {
  height: 25px;
  width: 25px;
}

.dr__navbar-icons a:first-child {
  padding-right: 25px;
}

.dr__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

@media screen and (max-width: 1050px) {
  .dr__navbar-links {
    display: none;
  }

  .dr__navbar-icons {
    display: none;
  }

  .dr__navbar-menu {
    display: flex;
    color: white;
  }
}

@media screen and (max-width: 700px) {
  .dr__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .dr__navbar {
    padding: 2rem;
  }

  .dr__navbar-menu_container {
    top: 20px;
  }

  .dr__navbar-menu_container-icons {
    display: block;
  }

  .dr__navbar-menu_container-icons img {
    height: 25px;
    width: 25px;
  }
}
