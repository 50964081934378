/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;700&family=Roboto+Mono:wght@300;500;700&display=swap");

:root {
  /* --font-family: "Open Sans", sans-serif; */
  font-family: "Crimson Text", serif;
  font-family: "Roboto Mono", monospace;

  --color-bg: #1b1b1b;
  --color-footer: #1b1b1b;
  --color-text: #fff;
  --color-subtext: #fff;
  --color-onhover: #2980b9;

  /* --gradient-text: linear-gradient(90deg, #ff7ce4 0%, #e4ff7c 100%); */
}
