.dr__experience {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 15rem;
}

.dr__experience-title {
  font-size: 40px;
  margin-bottom: 7rem;
  font-family: "Crimson Text", serif;
  letter-spacing: 3px;
}

.dr__experience-title h3 {
  transition: all 0.3s ease 0s;
}

.dr__experience-title h3:hover {
  color: var(--color-onhover);
  transform: scale(1.1);
}

.dr__experience-job1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
}

.dr__experience-job1__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  padding-right: 1rem;
}

.dr__experience-job1__info-title {
  font-size: 32px;
  padding-bottom: 15px;
}

.dr__experience-job1__info-company {
  font-size: 24px;
  padding-bottom: 15px;
}

.dr__experience-job1__info-points {
  font-size: 15px;
}

.dr__experience-job2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
}

.dr__experience-job2__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
}

.dr__experience-job2__info-title {
  font-size: 32px;
  padding-bottom: 15px;
}

.dr__experience-job2__info-company {
  font-size: 24px;
  padding-bottom: 15px;
}

.dr__experience-job2__info-points {
  width: 90%;
}

.dr__experience-job2__info-points ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: left;
  font-size: 15px;
}

.dr__experience li {
  padding-bottom: 15px;
}

.dr__experience-job__photo img {
  height: 200px;
  width: 400px;
}

@media screen and (max-width: 1050px) {
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 550px) {
  .dr__experience {
    margin-top: 5rem;
  }

  .dr__experience-job1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
  }

  .dr__experience-job2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
  }

  .dr__experience-job__photo img {
    height: 150px;
    width: 200px;
  }

  .dr__experience-job1__info-title {
    text-align: center;
  }

  .dr__experience-job2__info-title {
    text-align: center;
  }

  .dr__experience-job1__info-company {
    text-align: center;
  }

  .dr__experience-job2__info-company {
    text-align: center;
  }

  .dr__experience-job1__info-points ul {
    text-align: center;
  }

  .dr__experience-job2__info-points ul {
    text-align: center;
  }
}
