.dr__home {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  /* background-image: url("../../assets/bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center; */
}

.dr__home-pic {
  margin-bottom: 3rem;
}

.dr__home-pic img {
  /* clip-path: circle(); */
  height: 250px;
  width: 250px;
}

.dr__home-intro p {
  font-style: italic;
  padding-bottom: 1rem;
  font-family: "Crimson Text", serif;
  font-size: 48px;
  letter-spacing: 2px;
}

.dr__home-name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dr__home-name h1 {
  font-family: "Crimson Text", serif;
  font-style: bold;
  font-size: 70px;
  letter-spacing: 10px;
  transition: all 0.3s ease 0s;
}

.dr__home-name h1:hover {
  color: var(--color-onhover);
  transform: scale(1.1);
}

.dr__home-name img {
  padding-right: 25px;
  padding-left: 25px;
}

.dr__home-welcome {
  padding-top: 1rem;
  font-family: "Crimson Text", serif;
  font-size: 28px;
  letter-spacing: 2px;
}

@media screen and (max-width: 1050px) {
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 550px) {
  .dr__home-pic {
    margin-top: 5rem;
  }

  .dr__home-pic img {
    height: 150px;
    width: 150px;
  }

  .dr__home-intro p {
    font-size: 36px;
  }

  .dr__home-name h1 {
    font-size: 50px;
    text-align: center;
  }

  .dr__home-name img {
    padding-right: 35px;
    padding-left: 35px;
  }

  .dr__home-welcome {
    font-size: 22px;
    text-align: center;
  }
}
